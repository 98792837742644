export function currency(value: number) {
	return value.toLocaleString('cs-CZ', {
		style: 'currency',
		currency: 'CZK',
		maximumFractionDigits: 0,
	})
}

export function bytes(bytes: number, maxPrecision = 1) {
	const marker = 1024 // Change to 1000 if required
	const kiloBytes = marker
	const megaBytes = marker * marker
	const gigaBytes = marker * marker * marker

	const format = (value: number) => parseFloat(value.toFixed(maxPrecision)) // Ensures no trailing ".0" if not needed

	if (bytes < kiloBytes) {
		return `${bytes} B`
	} else if (bytes < megaBytes) {
		return `${format(bytes / kiloBytes)} KB`
	} else if (bytes < gigaBytes) {
		return `${format(bytes / megaBytes)} MB`
	} else {
		return `${format(bytes / gigaBytes)} GB`
	}
}
